import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/LoginPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/finish-signup/:token',
    name: 'FinishSignup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/FinishSignup.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/subscriptions',
    name: 'SubscriptionDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/SubscriptionDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/contacts',
    name: 'ContactsDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/ContactsDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/cooperations',
    name: 'CooperationsDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/CooperationsDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user'],
    },
  },

  {
    path: '/features',
    name: 'FeaturesDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/FeaturesDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/issues',
    name: 'IssuesDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/IssuesDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/mails',
    name: 'MailsDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/admin/MailsDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/links',
    name: 'LinksDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/admin/LinksDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/passwords',
    name: 'PasswordsDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/admin/PasswordsDashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/users-management',
    name: 'UsersManagement',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/UsersManagement.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/resources-management',
    name: 'ResourcesManagement',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ResourcesManagement.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/resources-management/:id',
    name: 'ResourcesManagementResource',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ResourceManage.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/privileges-management',
    name: 'PrivilegesManagement',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/PrivilegesManagement.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

export default router;
