import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

import axios from "axios";

import Directives from "./directives";
import Format from "./mixins/Format";

import type { UserInfo } from "./types";

import InputComponent from "./components/form-components/InputComponent.vue";
import DropdownComponent from "./components/form-components/DropdownComponent.vue";
import NavigationComponent from "./components/NavigationComponent.vue";
import ModalComponent from "./modal/ModalComponent.vue";
import './registerServiceWorker'

declare global {
  interface Window {
    isAuthenticated: boolean;
    userData: UserInfo;
  }
}
declare module "vue" {
  interface ComponentCustomProperties {
    window: Window;
    $format: Format;
  }
}
declare module "vue-router" {
  interface RouteMeta {
    requiresAuth?: boolean;
    roles?: string[];
  }
}

window.isAuthenticated = false;
router.beforeEach(function (to, from, next) {
  if (window.isAuthenticated) {
    if (to.meta.roles?.includes(window.userData?.role || "")) {
      if (
        (!window.userData.accesses["Contact"] && to.name === "ContactsDashboard") ||
        (!window.userData.accesses["Cooperation"] && to.name === "CooperationsDashboard")
      ) {
        next({ name: "SubscriptionDashboard" });
      } else {
        next();
      }
    } else {
      next({ name: "SubscriptionDashboard" });
    }
  } else {
    if (to.meta.requiresAuth === false) {
      next();
    } else {
      next({ name: "LoginPage" });
    }
  }
});

const app = createApp(App);
const pinia = createPinia();

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.isAuthenticated = false;
      if (
        router.currentRoute &&
        router.currentRoute.value &&
        router.currentRoute.value.meta &&
        router.currentRoute.value.meta.requiresAuth
      ) {
        router.push({ name: "LoginPage" });
      }
    }
    return Promise.reject(error);
  }
);

axios
  .get("/api/user-info/")
  .then((response) => {
    window.isAuthenticated = true;
    window.userData = response.data;
  })
  .catch(() => {
    window.isAuthenticated = false;
  })
  .finally(() => {
    Directives(app);

    app.config.globalProperties.window = window;
    app.config.globalProperties.$format = new Format();

    app.component("InputComponent", InputComponent);
    app.component("DropdownComponent", DropdownComponent);
    app.component("NavigationComponent", NavigationComponent);
    app.component("ModalComponent", ModalComponent);

    app.use(pinia);
    app.use(router);
    app.mount("#app");
  });
