import ClickOutside from "./directives/ClickOutside";
import Focus from "./directives/Focus";

import { App } from "vue";
import Sticky from "./directives/Sticky";
export default function (app: App) {
  ClickOutside(app);
  Focus(app);
  Sticky(app);
}
