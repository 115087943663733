import { defineStore } from "pinia";

export const generalStore = defineStore("generalStore", {
  state: () => {
    return {
      navigationOpened: false,
      loader: false,
    };
  },
  getters: {},
  actions: {
    setLoader(value: boolean) {
      this.loader = value;
    },
    setNavigation(value: boolean) {
      if (localStorage.getItem("navigationOpened")) {
        localStorage.setItem("navigationOpened", String(value));
      } else {
        localStorage.setItem("navigationOpened", String(false));
      }
      this.navigationOpened = value;
    },
  },
});
